<template>
  <base-section
    id="story"
    space="56"
    class="grey lighten-4"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="12"
        >
          <base-business-info title="SEKILAS TENTANG KAMI" text="PT. Orion Penjaminan Indonesia didirikan pada tanggal 12 April tahun 2023 dengan
Akta Pendirian No. 5 yang dibuat dihadapan Arief Yulianto,S.H., M. Kn Notaris di
Jakarta dan telah mendapat pengesahan dari Menteri Kehakiman RI tanggal 12 April
2023 Nomor: AHU- 0028748.AH.01.01.TAHUN 2023. Anggaran Dasar Perusahaan
telah mengalami perubahan. Perubahan anggaran dasar terakhir diubah dengan Akta
Perubahan Nomor: 09 tanggal 17 Mei 2023, yang dibuat oleh Arief Yulianto,S.H., M.
Kn Notaris di Jakarta dan telah mendapat persetujuan dari Menteri Hukum dan HAM
RI dengan Surat Keputusan Tanggal 17 Mei 2023 Nomor: AHU- AH.01.09-0118229">
          </base-business-info>
          <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="accent"
              large
              outlined
              to="sejarah-orionpenjaminan"
              class ="text-capitalize"
            >
              Baca selengkapnya
            </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionStory',
  }
</script>
